.removeScroll {
  overflow-y: scroll;
  /* height: calc(100vh); */
}

.removeScroll::-webkit-scrollbar {
  /* overflow-y: scroll; */
  display: none;
}

.markdown:focus {
  outline: none;
}

.hidden-checkbox {
  visibility: hidden;
}